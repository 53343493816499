export default [
    {
      path: '/',
        redirect:'/home'
    },
    // 新首页
    {
        path: '/home',
        component:()=>import('@/views/home/home1.vue')
    },
    // 新产品与服务
    {
        path: '/numberMemnagerView',
        component:()=>import('@/views/home/numberMemnagerView.vue')
    },
    // 解决方案
    {
        path: '/solutionView',
        component:()=>import('@/views/home/solutionView.vue')
    },
    //关于我们
    {
        path: '/aboutUsView',
        component:()=>import('@/views/home/aboutUsView.vue')
    },
    {
        path: '/detailsView',
        component:()=>import('@/views/home/detailsView.vue')
    },
    /*解決方案*/
    {
        path: '/solution',
        component:()=>import('@/views/solution/index')
    },
    /*解決方案*/
    {
        path: '/solutionDetails',
        component:()=>import('@/views/solution/solutionDetails')
    },
    /*产品与服务*/
    {
        path: '/productsService',
        component:()=>import('@/views/productsService/index')
    },
    /*客户案例*/
    {
        path: '/caseDetails',
        component:()=>import('@/views/case/caseDetails')
    },
    /*BPM业务流程管理系统*/
    {
        path:'/bpm',
        component:()=>import('@/views/products/bpm')
    },
    /*CMS合同管理系统*/
    {
        path: '/cms',
        component:()=>import('@/views/products/cms')
    },
    /*HRM人力资源管理系统*/
    {
        path: '/hrm',
        component:()=>import('@/views/products/hrm')
    },
    /*PLM产品生命周期管理系统*/
    {
        path: '/plm',
        component:()=>import('@/views/products/plm')
    },
    /*PMS项目管理系统*/
    {
        path: '/pms',
        component:()=>import('@/views/products/pms')
    },
    /*会议管理系统*/
    {
        path: '/meeting',
        component:()=>import('@/views/products/meeting')
    },
    /*SRM采购管理系统*/
    {
        path: '/srm',
        component:()=>import('@/views/products/srm')
    },
    /*客服管理系统*/
    {
        path: '/customer',
        component:()=>import('@/views/products/customer')
    },
    /*档案管理系统*/
    {
        path: '/record',
        component:()=>import('@/views/products/record')
    },
    /*千企云-低代码开发平台*/
    {
        path: '/QianQiCloud',
        component:()=>import('@/views/products/QianQiCloud')
    },
    /*QMS系统*/
    {
        path: '/QMSsystem',
        component:()=>import('@/views/products/QMSsystem')
     },
    /*OMS系统*/
    {
        path: '/OMSsystem',
        component:()=>import('@/views/products/OMSsystem')
    },
]
