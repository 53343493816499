import Vue from 'vue'
import vueRouter from 'vue-router'
import homeRouter from "@/router/homeRouter";
import products from './products'

Vue.use(vueRouter)
const originalPush = vueRouter.prototype.push
vueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
    ...homeRouter,
    ...products,
  {
    path: '/404',
    name: 'NotFound',
    component: () => import ("@/views/NotFound"),
  },
  {
    path: "*",
    redirect: '/404'
  }
]

const router = new vueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})

export default router
