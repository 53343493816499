const routers = [
 //数字办公
    {
        path: '/OAsystem',
        name:"OAsystem",
        component:()=>import('@/views/products/OAsystem.vue')
    },
    //ERP企业资源系统
    {
        path: '/ERPsystem',
        name:"ERPsystem",
        component:()=>import('@/views/products/ERPsystem.vue')
    },
     //财务管理
     {
        path: '/finance',
        name:"finance",
        component:()=>import('@/views/products/finance.vue')
    },
    //CRM系统
    {
        path: '/CRMsystem',
        name:"CRMsystem",
        component:()=>import('@/views/products/CRMsystem.vue')
    },
    //SCM供应链管理系统
    {
        path: '/SCMsystem',
        name:"SCMsystem",
        component:()=>import('@/views/products/SCMsystem.vue')
    },
    //WMS仓库管理系统
    {
        path: '/warehouse',
        name:"warehouse",
        component:()=>import('@/views/products/warehouse.vue')
    },
    //KMS知识管理系统
    {
        path: '/kmsKnowledge',
        name:"kmsKnowledge",
        component:()=>import('@/views/products/kmsKnowledge.vue')
    },
    //数字应用
    {
        path: '/digitalApplications',
        name:"digitalApplications",
        component:()=>import('@/views/products/digitalApplications.vue')
    },
    //企业大数据
    {
        path: '/enterpriseBigData',
        name:"enterpriseBigData",
        component:()=>import('@/views/products/enterpriseBigData.vue')
    },
    //mes系统
    {
        path: '/MESsystem',
        name:"MESsystem",
        component:()=>import('@/views/products/MESsystem.vue')
    },
    
]

export default routers